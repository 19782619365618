
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































.duo-ctas {
  display: flex;
  justify-content: space-between;
  padding-block: 6rem;
  background-color: $c-pampas;

  &.is-dark {
    background-color: $c-black;

    ::v-deep {
      .curtain {
        border-color: $c-white;
      }

      .curtain__active .curtain__arrow {
        fill: $c-black;
      }

      .curtain__inner:not(.curtain__active) {
        .curtain__label {
          color: $c-white;
        }
        .curtain__arrow {
          fill: $c-white;
        }
      }
    }
  }
}

.duo-ctas__title {
  max-width: 54rem;
  margin-inline: auto;
  margin-bottom: 4rem;
  text-align: center;

  .is-dark & {
    color: $c-white;
  }
}

.duo-ctas__items {
  @extend %list-nostyle;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;

  .curtain {
    width: 100%;
    text-align: left;
  }

  ::v-deep {
    .curtain__inner {
      display: inline-flex;
      padding: 3rem;
    }

    .curtain__label {
      // prettier-ignore
      @include fluid(font-size, (xxs: 15px, m: 20px));
    }
  }

  @include mq(l) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
