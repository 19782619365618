
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































.jobs-slider {
  @include liquid(padding-block, 40px, 80px);

  &.with-cards-picture {
    background-color: $c-pampas;
  }
}

.jobs-slider-inner {
  text-align: center;
}

.jobs-slider__htmltext {
  max-width: 84rem;
  margin-inline: auto;
  text-align: center;

  ::v-deep {
    b {
      font-weight: 700;
    }
  }
}
