
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































.card-job {
  text-align: left;
  background-color: $c-pampas;
}

.card-job__picture-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;

  ::v-deep {
    .clip {
      aspect-ratio: 1;
    }

    .card-job__picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.card-job__content {
  @include liquid(padding, 20px, 30px);
}

.card-job__category {
  margin-bottom: 2rem;
}

.card-job__title {
  @include liquid(font-size, 20px, 30px);
  @include liquid(line-height, 28px, 36px);

  font-weight: 600;
}

.card-job__details {
  color: $c-black;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2;
  opacity: 0.4;
}

.card-job__link {
  @include liquid(font-size, 13px, 15px);

  display: flex;
  align-items: center;
  margin-top: 3rem;
  font-weight: 600;
  line-height: 1.5rem;

  span {
    margin-right: 0.4rem;
  }

  &::before {
    @include get-all-space;

    content: '';
  }
}
