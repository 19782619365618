
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































@import '~flickity/dist/flickity.css';

.flickity-cell {
  width: 80%;
  margin-right: 2rem;

  .with-cards-picture & {
    width: auto;
    height: 40rem;
    margin-right: 2rem;
  }

  @include mq(m) {
    width: 30%;
    margin-right: 4rem;

    .with-cards-picture & {
      width: auto;
      height: 60rem;
      margin-right: 2rem;
    }
  }
}

.carousel__controls {
  margin-top: 2rem;

  button {
    @extend %button-nostyle;

    margin-inline: 0.5rem;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}

.carousel__controls__arrow {
  fill: $c-black;
  width: 5rem;
  margin-left: auto;
}
