
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































.card-testimonial {
  text-align: left;
  background-color: $c-white;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.card-testimonial__picture-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 440/295;

  ::v-deep {
    .clip {
      aspect-ratio: 1;
    }

    .card-testimonial__picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.card-testimonial__content {
  @include liquid(padding-block, 10px, 20px);
  @include liquid(padding-inline, 20px, 40px);
}

.card-testimonial__icon {
  fill: $c-main;
}

.card-testimonial__title {
  margin-top: 0.4rem;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3.2rem;
}

.card-testimonial__htmltext {
  @include liquid(font-size, 13px, 15px);

  margin-top: 1rem;
  line-height: 24px;
}

.card-testimonial__name {
  margin-top: 4rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 3rem;
}

.card-testimonial__job {
  @include liquid(line-height, 24px, 30px);

  color: $c-gray-dark;
  font-size: 1.5rem;
}
